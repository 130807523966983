import React from 'react';
import { styled } from '@mui/material/styles';
const PREFIX = 'DataTable';

const classes = {
  table: `${PREFIX}-table`,
  th: `${PREFIX}-th`,
  td: `${PREFIX}-td`
};

const Root = styled('table')((
  {
    theme
  }
) => ({
  [`&.${classes.table}`]: {
    width: '100%'
  },

  [`& .${classes.th}`]: {
    textAlign: 'left',
    textTransform: 'lowercase',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: 2,
    color: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      fontSize: '18px'
    }
  },

  [`& .${classes.td}`]: {
    textAlign: 'right',
    fontFamily: 'Propmt',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: 2,
    fontWeight: 600,
    color: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      fontSize: '18px'
    }
  }
}));

function DataTable({ attributes }) {


  if(!attributes.length) {
    return null
  }

  return (
    <Root className={classes.table}>
      <tbody>
        {
          attributes.map(attribute => (
            <tr key={attribute.name}>
              <th className={classes.th}>{ attribute.name }</th>
              <td className={classes.td}>{ attribute.options[0]}</td>
            </tr>
          ))
        }
      </tbody>
    </Root>
  );
}

export default DataTable;