import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Container } from '@mui/material'
import ProductCardDetailed from '@components/ProductCardDetailed/ProductCardDetailed'
import { stripHtmlTags } from '@src/utility'


const ProductVariantCardTemplate = ({ 
  data: { parentProduct, product, randomProducts, placeholderLocalFile },
  pageContext: { pagePathStart } 
}) => {
  const placeholderImage = {
    localFile: placeholderLocalFile,
    altText: 'no image',
    id: 0.01
  }

  return (
    <>
      <SEO 
        title={product.name} 
        description={
          stripHtmlTags(
            product.shortDescription  ||
            product.description ||
            parentProduct?.shortDescription ||
            parentProduct?.description
          )
            ?.slice(0, 155)
        } />
      <Container maxWidth="md" disableGutters>
        <ProductCardDetailed 
        product={product} 
        parentProduct={parentProduct} 
        pagePathStart={pagePathStart} 
        randomProducts={randomProducts} 
        placeholderImage={placeholderImage} 
        />
      </Container>
    </>
  )
}

export default ProductVariantCardTemplate

export const pageQuery = graphql`query VariableProductById($parentProductId: String!, $productId: String!, $randomProductIds: [String!]) {
  placeholderLocalFile: file(relativePath: {eq: "woocommerce-placeholder-1024.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, width: 600, layout: CONSTRAINED)
    }
  }
  parentProduct: wpProduct(id: {eq: $parentProductId}) {
    id
    name
    description
    shortDescription
    slug
    productCategories {
      nodes {
        name
        slug
      }
    }
    galleryImages {
      nodes {
        id
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 80, layout: CONSTRAINED)
          }
          publicURL
        }
      }
    }
    attributes {
      nodes {
        name
        options
      }
    }
    elevatorOptions {
      option1 {
        optionName
        optionPrice
      }
      option2 {
        optionName
        optionPrice
      }
      option3 {
        optionName
        optionPrice
      }
      option4 {
        optionName
        optionPrice
      }
      option5 {
        optionName
        optionPrice
      }
    }
    ... on WpGroupProduct {
      price
    }
  }
  product: wpProduct(id: {eq: $productId}) {
    id
    name
    description
    shortDescription
    slug
    productCategories {
      nodes {
        name
        slug
      }
    }
    image {
      id
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(width: 380, height: 294, quality: 80, layout: CONSTRAINED)
        }
        publicURL
      }
    }
    galleryImages {
      nodes {
        id
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 80, layout: CONSTRAINED)
          }
          publicURL
        }
      }
    }
    attributes {
      nodes {
        name
        options
      }
    }
    ... on WpSimpleProduct {
      price
    }
  }
  randomProducts: allWpProduct(filter: {id: {in: $randomProductIds}}) {
    nodes {
      id
      slug
      productCategories {
        nodes {
          name
          slug
        }
      }
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 140, quality: 90, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`
