import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from 'clsx';

const PREFIX = 'ImagePicker';

const classes = {
  imageWrapper: `${PREFIX}-imageWrapper`,
  activeImg: `${PREFIX}-activeImg`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.imageWrapper}`]: {
    display: 'block',
    boxSizing: 'border-box',
    border: '4px solid transparent',
    transition: 'all 0.3s',
    cursor: 'pointer'
  },

  [`& .${classes.activeImg}`]: {
    border: `4px solid ${theme.palette.secondary.main}`,
    boxShadow: `inset 6px 6px 10px ${theme.palette.secondary.main}`
  }
}));

const ImagePicker = ({ images, currentImage, setCurrentImage }) => {


  if(!images) {
    return 'no products to show'
  }

  return (
    <StyledGrid container spacing={1}>
        {
          images.map((slide, i) => {
            return (
              <Grid item key={slide.id} xs={6} sm={4} md={3} className={classes.slide} onClick={
                () => {
                  setCurrentImage({ 
                    file: slide.localFile, 
                    alt: slide.altText,
                    id: slide.id
                  })
                }
              }>
                  <div className={clsx(classes.imageWrapper, slide.id === currentImage.id && classes.activeImg)}>
                    <GatsbyImage
                      image={slide.localFile.childImageSharp.gatsbyImageData}
                      height={42}
                      alt={`image ${i + 1}, ${slide.altText}`}
                      style={{
                        display: 'block',
                        maxHeight: 42
                      }}
                      />
                  </div>
              </Grid>
            );
          })
        }
    </StyledGrid>
  );
}

export default ImagePicker;