import React, { useState, useEffect, useContext, useMemo } from 'react'
import { styled } from '@mui/material/styles';
import { Typography, Grid, useMediaQuery, IconButton } from '@mui/material';
import { Button } from 'gatsby-theme-material-ui'
import Search from '@mui/icons-material/Search'
import parse from "html-react-parser"
import TitleHeader from '@components/TitleHeader'
import ImagePicker from './ImagePicker'
import DataTable from './DataTable'
import SimpleSlider from './SimpleSlider'
import FavoriteButton from '@components/FavoriteButton'
import { SeenProductsSetContext, SeenProductsStateContext } from '../../context/seenProductsContext'
import NavigationActions from './NavigationActions'
import { deduplicateById } from '@src/utility'
import Breadcrumbs from '@components/Breadcrumbs'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import { GRAIN_CONVEYORS, GRAIN_ELEVATORS } from '../../const'
const PREFIX = 'ProductCardDetailed';

const classes = {
  container: `${PREFIX}-container`,
  image: `${PREFIX}-image`,
  cardHeader: `${PREFIX}-cardHeader`,
  h2: `${PREFIX}-h2`,
  price: `${PREFIX}-price`,
  bigPrice: `${PREFIX}-bigPrice`,
  actionsContainer: `${PREFIX}-actionsContainer`,
  iconButton: `${PREFIX}-iconButton`,
  icon: `${PREFIX}-icon`,
  imagePickerWrapper: `${PREFIX}-imagePickerWrapper`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },

  [`& .${classes.image}`]: {
    display: 'block',
    width: "auto",
    height: 222,
    [theme.breakpoints.up("md")]: {
      height: 393
    }
  },

  [`& .${classes.cardHeader}`]: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    textAlign: 'left',
    background: theme.palette.background.transparentGrey,
    padding: theme.spacing(2)
  },

  [`& .${classes.h2}`]: {
    margin: 0,
    marginTop: theme.spacing(1),
    color: theme.palette.common.white,
    fontFamily: "Krub",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "117.4%",
    '& span': {
      display: 'block',
      lineHeight: '155%'
    }
  },

  [`& .${classes.price}`]: {
    color: theme.palette.common.white,
    fontFamily: "Prompt",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px"
  },

  [`& .${classes.bigPrice}`]: {
    display: 'block',
    fontFamily: "Prompt",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.actionsContainer}`]: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    right: 0,
    top: 0,
    background: "#E7B607A6", //65% opacity
    [theme.breakpoints.up("md")]: {
      top: 'auto',
      bottom: 0,
      background: "#E7B6075C", //36% opacity
    }
  },

  [`& .${classes.iconButton}`]: {
    color: theme.palette.common.white,
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.common.white
  },

  [`& .${classes.imagePickerWrapper}`]: {
    margin: theme.spacing(4, 0)
  }
}));

const LoadableFsLightbox = loadable.lib(() => import('fslightbox-react'))

function ProductCardDetailed({ product, parentProduct, pagePathStart, randomProducts, placeholderImage }) {

  const matchesTablet = useMediaQuery(theme => theme.breakpoints.up("sm"))

  const images = useMemo(() => {
    const productImages = product.galleryImages?.nodes || []
    const parentProductImages = parentProduct?.galleryImages?.nodes || []
  
    return (product.image || productImages.length || parentProductImages.length) ? 
      deduplicateById([ product.image, ...productImages, ...parentProductImages ]) :
      [placeholderImage]

  }, [product.image, product.galleryImages, parentProduct])
  
  const [ currentImage, setCurrentImage ] = useState({
    file: images[0]?.localFile,
    alt: images[0].altText,
    id: images[0].id
  })

  const productAttributes = product.attributes?.nodes || []
  const parentProductAttributes = parentProduct?.attributes?.nodes || []
  const allAttributes = [ ...productAttributes, ...parentProductAttributes ]

  const description = 
    product.shortDescription || 
    product.description ||
    parentProduct?.shortDescription ||
    parentProduct?.description

  const seenProducts = useContext(SeenProductsStateContext)
  const setSeenProducts = useContext(SeenProductsSetContext)

  const [ toggler, setToggler ] = useState(false)

  const handleZoomClick = e => {
    e.stopPropagation()
    e.preventDefault()
    setToggler(!toggler)
  }

  useEffect(() => {
    if(seenProducts.find(nextProduct => nextProduct.id === product.id)) {
      return
    }

    setSeenProducts([
      ...seenProducts.slice(Math.max(seenProducts.length - 6, 0)),
      {
        id: product.id,
        slug: product.slug,
        productCategories: product.productCategories,
        image: product.image || product?.galleryImages?.nodes?.[0],
        parentProduct
      }
    ])
  }, [])

  if(!product) return 'loading...'
  
  return (
    <Root className={classes.container}>
        <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <TitleHeader
            title={ pagePathStart === '/new-conveyors' ?
            GRAIN_CONVEYORS :
              pagePathStart === '/new-elevators' ?
                GRAIN_ELEVATORS :
                  'Used equipment'
            }
            titleLink={pagePathStart}
            additionalContent={<Button to={`/all-price-lists#${parentProduct.id}`} variant="contained" color="secondary">View all price lists</Button>}
          />
          {
            parentProduct &&
            <Breadcrumbs links={
              [
                { name: parentProduct.productCategories?.nodes?.[0]?.name, path: `/${parentProduct.productCategories?.nodes?.[0]?.slug}` },
                { name: parentProduct.name, path: `/${parentProduct.productCategories?.nodes?.[0]?.slug}/${parentProduct.slug}` }
              ]
            } />
          }
          {
            matchesTablet ?
            <Grid container spacing={4}>
              <Grid item sm={4}>
                <div className={classes.image} style={{ display: 'grid'}}>
                  <GatsbyImage

                    key={currentImage.id}
                    image={getImage(currentImage.file)}
                    alt={product.name}
                    style={{
                      gridArea: "1/1",
                    }}
                    />
                  <div style={{ gridArea: "1/1", position: "relative"}}>
                    <div className={classes.actionsContainer}>
                      {
                        !!images.length &&
                        <IconButton onClick={handleZoomClick} className={classes.iconButton} size="large">
                          <Search style={{fontSize: 28, color: '#ffffff'}} />
                        </IconButton>
                      }
                      <FavoriteButton product={product} size="medium" activeColor="#1B4521" />
                    </div>
                  </div>
                </div>
                <div className={classes.imagePickerWrapper}>
                  <ImagePicker images={images} currentImage={currentImage} setCurrentImage={setCurrentImage} />
                </div>
              </Grid>
              <Grid item sm={8}>
                <Typography color="textPrimary" component="h2" variant="h3">
                  { parse(product.name) }
                </Typography>
                {
                  product.price &&
                  <strong className={classes.bigPrice}>{product.price}</strong>
                }
                {
                  allAttributes && <DataTable attributes={allAttributes} />
                }
                {
                  !!description && <Typography component="div" color="textPrimary">{parse(description)}</Typography>
                }
                <NavigationActions pagePathStart={pagePathStart} parentProduct={parentProduct} justifyContent="flex-start" />
              </Grid>
            </Grid> 
            
            :
            
            <>
                <div className={classes.image} style={{ display: 'grid'}}>
                  <GatsbyImage
                    key={currentImage.id}
                    image={getImage(currentImage.file)}
                    alt={product.name}
                    style={{
                      gridArea: "1/1",
                    }}
                    />
                  <div style={{ gridArea: "1/1", position: "relative"}}>
                    <header className={classes.cardHeader}>
                      {
                        product.price &&
                        <small className={classes.price}>{product.price}</small>
                      }
                      <h2 className={classes.h2}>
                        <span>{parse(product.name)}</span>
                      </h2>
                    </header>
                    <div className={classes.actionsContainer}>
                      {
                        !!images.length &&
                        <IconButton onClick={handleZoomClick} className={classes.iconButton} size="large">
                          <Search style={{fontSize: 28, color: '#ffffff'}} />
                        </IconButton>
                      }
                      <FavoriteButton product={product} size="medium" activeColor="#1B4521" />
                    </div>
                  </div>
                </div>
                <div style={{minHeight: 162, marginTop: 16}}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <ImagePicker images={images} currentImage={currentImage} setCurrentImage={setCurrentImage} />
                    </Grid>
                    <Grid item xs={7} color='textPrimary'>
                      {
                        allAttributes && <DataTable attributes={allAttributes} />
                      }
                      {
                        !!description && parse(description)
                      }
                    </Grid>
                  </Grid>
                </div>
                <NavigationActions pagePathStart={pagePathStart} parentProduct={parentProduct} justifyContent="flex-end" />
            </>
          }
        <LoadableFsLightbox>
          {
            ({ default: FsLightbox}) => FsLightbox ? 
              <FsLightbox
              toggler={ toggler }
              sources={ images.map(image => (
                image.localFile?.publicURL
              )) }
              /> : 'loading...'
          }
        </LoadableFsLightbox>
        </header>
        {
          !!randomProducts?.nodes?.length && (
            <>
              <Typography component="h2" variant="body2" style={{ fontSize: 12, marginBottom: 6 }}>similar products</Typography>
              <SimpleSlider 
                products={
                  randomProducts.nodes
                    .filter(nextProduct => nextProduct.id !== product.id)
                    .map(product => ({ ...product, parentProduct }))
                } 
                placeholderImage={placeholderImage} 
              />
            </>
          )
        }
        {
          (seenProducts.length - 1 > 0) && (
            <>
              <Typography component="h2" variant="body2" style={{ fontSize: 12, marginBottom: 6 }}>seen products</Typography>
              <SimpleSlider products={seenProducts.filter(nextSeenProduct => nextSeenProduct.id !== product.id)} placeholderImage={placeholderImage} />
            </>
          )
        }
      </article>
    </Root>
  );
}

export default ProductCardDetailed;