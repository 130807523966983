import React from 'react'
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { Button } from 'gatsby-theme-material-ui'
import { NavigateBefore } from '@mui/icons-material';

const PREFIX = 'NavigationActions';

const classes = {
  buttonsContainer: `${PREFIX}-buttonsContainer`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.buttonsContainer}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  }
}));

function NavigationActions({ pagePathStart, parentProduct, justify }) {


  return (
    <StyledGrid container className={classes.buttonsContainer} spacing={2} justifyContent={justify}>
      <Grid item xs={12} sm={'auto'}>
        <Button 
        to={parentProduct ? `${pagePathStart}/${parentProduct.slug}` :  pagePathStart} 
        variant="outlined" 
        color="primary" 
        startIcon={<NavigateBefore />} 
        fullWidth>
          Back to catalogue
        </Button>
      </Grid>
      <Grid item xs={12} sm={'auto'}>
        <Button to="/my-price-list" variant="outlined" color="secondary" fullWidth>View my price list</Button>
      </Grid>
    </StyledGrid>
  );
}

export default NavigationActions;